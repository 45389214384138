import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import YearSelector from "./year-selector";

export default function SalaryFormHeader(props) {
  const { t, i18n } = useTranslation();
  const { type, path } = props;
  return (
    <Box display={"flex"} justifyContent={"flex-start"} sx={{ mb: 1 }}>
      <Typography sx={{ mt: "11px" }} variant="h4">
        {t("salary-form-description")} {type}
      </Typography>
      <YearSelector path={path} />
    </Box>
  );
}
