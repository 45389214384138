import React from "react";
import { useTranslation } from "react-i18next";
import "./App.css";
import logo from "./resources/remote-letter-header.svg";
import { Box, Link, Typography } from "@mui/material";
import { recordEvent } from "./App";
import { PaddedDiv } from "./AppStyles";

export default function AddBanner(props) {
  const { t, i18n } = useTranslation();
  const { bgColor = "var(--background-black)" } = props;

  return (
    <PaddedDiv>
      <Box
       sx={{
         p: 2,
         display: "flex",
         flexDirection: "row",
         alignItems: "center",
         justifyContent: "center",
         borderRadius: "16px",
         borderStyle: "dashed solid"
       }}
      >
        <Typography sx={{ mr: 1 }} variant="h6">
          {t("ad-banner")}
        </Typography>
        <Link
           variant="h6"
           target="_top"
           href={`mailto:info@remoteflags.com?subject=e-salario anuncios&body=Qual é o preço para anunciar no e-salario?`}
           onClick={recordEvent("esalario-contact-us")}
        >
          {t("contact-us")}
        </Link>
      </Box>
    </PaddedDiv>
  );
}
