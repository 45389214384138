import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { Enquadramento } from "salario-engine-js";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

export default function FormSalary(props) {
  const { t, i18n } = useTranslation();
  const { _handleReset, _handleSubmit, fields, _handleFieldChange, _handleBooleanChange, type } = props;
  const [errorField, setErrorField] = useState({
    salario: false,
    dependentes: false,
    valorSubAlimentacao: false,
    diasTrabalho: false,
  });

  function _renderIrsOptions() {
    const irsOption = Object.keys(Enquadramento).map((key) => (
      <MenuItem value={key} key={key}>
        {t(Enquadramento[key])}
      </MenuItem>
    ));
    return irsOption;
  }

  function _validateField(event) {
    if (event.target.value) {
      setErrorField({ ...errorField, [event.target.name]: false });
    } else {
      setErrorField({ ...errorField, [event.target.name]: true });
    }
  }

  return (
    <form onSubmit={_handleSubmit}>
      <NumericFormat
        sx={{ mt: 3 }}
        id="salario"
        name="salario"
        autoFocus
        fullWidth
        size="medium"
        label={t(`salary-form-salario-${type}`)}
        placeholder={t(`salary-form-salario-${type}`)}
        onChange={_handleFieldChange}
        value={fields.salario}
        error={errorField.salary}
        focused={errorField.salary}
        inputProps={{
          maxLength: 6,
          min: 0,
          max: 999999,
          onBlur: (event) => {
            _validateField(event);
          },
          onKeyDown: (event) => {
            if (event.key === "Enter") {
              _validateField(event);
            }
          },
        }}
        customInput={TextField}
        decimalSeparator="."
        allowedDecimalSeparators={[",", "."]}
      />
      <FormHelperText error={errorField.salario}>
        {(errorField.salario && t(`salary-form-salario-${type}-error`)) || " "}
      </FormHelperText>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "left", md: "center" },
        }}
      >
        <FormControl sx={{ width: { xs: "100%", md: "50%" } }}>
          <InputLabel id="situacao-label">{t("salary-form-irs-situacao")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            name="situacao"
            id="situacao"
            defaultValue="NAO_CASADO"
            label={t("salary-form-irs-situacao")}
            onChange={_handleFieldChange}
          >
            {_renderIrsOptions()}
          </Select>
        </FormControl>
        <NumericFormat
          sx={{
            ml: { xs: 0, md: 2 },
            width: { xs: "60%", md: "18%" },
            mt: { xs: 2, md: 0 },
          }}
          id="dependentes"
          name="dependentes"
          fullWidth
          size="medium"
          label={t("salary-form-dependentes")}
          placeholder={t("salary-form-dependentes")}
          onChange={_handleFieldChange}
          value={fields.dependentes}
          focused={errorField.dependentes}
          error={errorField.dependentes}
          inputProps={{
            onBlur: (event) => {
              _validateField(event);
            },
            onKeyDown: (event) => {
              if (event.key === "Enter") {
                _validateField(event);
              }
            },
          }}
          customInput={TextField}
          isAllowed={(values) => {
            const { formattedValue, floatValue } = values;
            return formattedValue === "" || floatValue <= 99;
          }}
        />
        <FormControlLabel
          sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}
          control={
            <Checkbox
              id="incapacidade"
              name="incapacidade"
              onChange={_handleBooleanChange}
              value={fields.incapacidade}
            />
          }
          label={t("salary-form-irs-incapacidade")}
        />
      </Box>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "left", md: "center" },
        }}
      >
        <NumericFormat
          sx={{ width: { xs: "100%", md: "50%" } }}
          id="valorSubAlimentacao"
          name="valorSubAlimentacao"
          fullWidth
          size="medium"
          label={t("salary-form-valorSubAlimentacao")}
          placeholder={t("salary-form-valorSubAlimentacao")}
          onChange={_handleFieldChange}
          value={fields.valorSubAlimentacao}
          focused={errorField.valorSubAlimentacao}
          error={errorField.valorSubAlimentacao}
          inputProps={{
            onBlur: (event) => {
              _validateField(event);
            },
            onKeyDown: (event) => {
              if (event.key === "Enter") {
                _validateField(event);
              }
            },
          }}
          customInput={TextField}
          decimalSeparator="."
          allowedDecimalSeparators={[",", "."]}
          isAllowed={(values) => {
            const { formattedValue, floatValue } = values;
            return formattedValue === "" || floatValue <= 999;
          }}
        />
        <NumericFormat
          sx={{
            ml: { xs: 0, md: 2 },
            width: { xs: "60%", md: "18%" },
            mt: { xs: 2, md: 0 },
          }}
          id="diasTrabalho"
          name="diasTrabalho"
          fullWidth
          size="medium"
          label={t("salary-form-diasTrabalho")}
          placeholder={t("salary-form-diasTrabalho")}
          onChange={_handleFieldChange}
          value={fields.diasTrabalho}
          focused={errorField.diasTrabalho}
          error={errorField.diasTrabalho}
          inputProps={{
            maxLength: 2,
            min: 0,
            onBlur: (event) => {
              _validateField(event);
            },
            onKeyDown: (event) => {
              if (event.key === "Enter") {
                _validateField(event);
              }
            },
          }}
          customInput={TextField}
          isAllowed={(values) => {
            const { formattedValue, floatValue } = values;
            return formattedValue === "" || floatValue <= 31;
          }}
        />
        <FormControlLabel
          sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}
          control={
            <Checkbox
              id="cartaoSubAlimentacao"
              name="cartaoSubAlimentacao"
              onChange={_handleBooleanChange}
              checked={fields.cartaoSubAlimentacao}
            />
          }
          label={t("salary-form-sub-alimentacao-cartao")}
        />
      </Box>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button size="large" sx={{ mt: 3 }} variant="outlined" onClick={_handleReset}>
          {t("salary-form-reset")}
        </Button>
        <Button size="large" sx={{ mt: 3, ml: 1 }} variant="contained" type="submit">
          {t("salary-form-submit")}
        </Button>
      </Box>
    </form>
  );
}
