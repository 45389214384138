import { styled } from "@mui/system";

export const PaddedDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down("md")]: {
    paddingTop: "8px",
    paddingBottom: "40px",
    paddingLeft: "24px",
    paddingRight: "24px",
    margin: "auto",
    maxWidth: "800px",
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: "8px",
    paddingBottom: "40px",
    paddingLeft: "74px",
    paddingRight: "74px",
    margin: "auto",
    maxWidth: "900px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingTop: "8px",
    paddingBottom: "40px",
    paddingLeft: "74px",
    paddingRight: "74px",
    margin: "auto",
    maxWidth: "900px",
  },
}));

export const HeaderFooterDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down("md")]: {
    paddingLeft: "16px",
    paddingRight: "16px",
    margin: "auto",
    maxWidth: "840px",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "40px",
    paddingRight: "40px",
    margin: "auto",
    maxWidth: "1200px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "40px",
    paddingRight: "40px",
    margin: "auto",
    maxWidth: "1200px",
  },
}));

export const PaddedNav = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0%",
    marginRight: "0%",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  [theme.breakpoints.up("lg")]: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "24px",
    paddingRight: "24px",
    minWidth: "1200px",
  },
}));
