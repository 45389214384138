import { NavLink as BaseNavLink } from "react-router-dom";
import { Button, useTheme } from "@mui/material";

export function NavButton({ to, children }) {
  const theme = useTheme();
  const getNavLinkStyle = (isActive) => ({
    backgroundColor: isActive ? theme.palette.primary.main : "transparent",
    color: isActive ? "#ffffff" : theme.palette.primary.main,
    fontFamily: "RobotoSlab",
  });

  return (
    <BaseNavLink to={to}>
      {({ isActive }) => (
        <Button type="text" style={getNavLinkStyle(isActive)}>
          {children}
        </Button>
      )}
    </BaseNavLink>
  );
}
