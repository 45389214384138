import axios from "axios";
const URL = "https://buxmriqgid.execute-api.eu-west-1.amazonaws.com/latest/hash";

function createHash(dataReceived) {
  return axios
    .post(URL, dataReceived)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // TODO add logs
    });
}

function getValuesFromHash(sharedHash, doneFn, errorFn) {
  return axios
    .get(URL + "/" + sharedHash)
    .then(function (response) {
      doneFn(response);
    })
    .catch(function (error) {
      errorFn(error);
    });
}

export { createHash, getValuesFromHash };
