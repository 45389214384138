import React from "react";
import { useTranslation } from "react-i18next";
import "./App.css";
import logo from "./resources/remote-letter-header.svg";
import { Typography } from "@mui/material";
import { recordEvent } from "./App";

export default function PoweredByRemoteFlags(props) {
  const { t, i18n } = useTranslation();
  const { bgColor = "var(--background-black)" } = props;

  return (
    <div className="powered-by">
      <Typography sx={{ mb: 1 }} variant="h6">
        {t("powered-by")}
      </Typography>
      <a href="https://remoteflags.com" target="_blank" onClick={recordEvent("esalario-event-remoteflags-link")}>
        <img width="300" height="24" src={logo} className="remoteflags-logo" alt="remoteflags logo" />
      </a>
    </div>
  );
}
