import * as React from "react";
import { AppBar, Box, IconButton, Typography, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PaddedNav } from "./AppStyles";
import { useNavigate } from "react-router-dom";
import { NavButton } from "./nav-button";
import ListIcon from "@mui/icons-material/List";

export default function NavBar(props) {
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = React.useState(null);
  const open = Boolean(anchorElement);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <Box className="navbar-and-menu" sx={{ flexGrow: 1 }}>
      <AppBar className="navbar" position="sticky" sx={{ pt: 1 }}>
        <PaddedNav>
          <Box display={"flex"} justifyContent={"space-between"} sx={{ pt: 1, alignItems: "center", flexWrap: "wrap" }}>
            <Box>
              <Typography sx={{ cursor: "pointer" }} variant="h4" onClick={() => navigate("/salario-liquido/")}>
                {t("salary-form-title")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                id="icon-nav-button"
                aria-controls={open ? "nav-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                color="inherit"
              >
                <Typography sx={{ mr: 1 }}>{t("calculators")}</Typography>
                <ListIcon aria-label="menu" sx={{ mt: 0.2 }} />
              </IconButton>
              <Menu
                id="nav-menu"
                anchorEl={anchorElement}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "icon-nav-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <NavButton to="/salario-liquido">{t("nav-net-salary")}</NavButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavButton to="/salario-bruto">{t("nav-gross-salary")}</NavButton>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </PaddedNav>
      </AppBar>
    </Box>
  );
}
