import { Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import { PaddedDiv } from "./AppStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const newForm = () => navigate("/");

  return (
    <PaddedDiv>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          mt: 1,
          mb: 2,
        }}
      >
        <Typography sx={{ mt: 1 }} variant="h1">
          {t("error-page-error-404")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          mt: 1,
          mb: 2,
        }}
      >
        <Typography sx={{ mt: 1 }} variant="h4">
          {t("error-page-page-not-found")}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button onClick={newForm} variant="contained">
          {t("error-page-home-page")}
        </Button>
      </Box>
    </PaddedDiv>
  );
}
