import React, { useMemo, useState } from "react";
import "./App.css";
import SalaryForm from "./calculators/salary-form";
import Footer from "./footer";
import NavBar from "./nav-bar";
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";
import { Box, CssBaseline, Switch } from "@mui/material";
import LightMode from "@mui/icons-material/LightMode";
import DarkMode from "@mui/icons-material/DarkMode";
import { HeaderFooterDiv } from "./AppStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Toaster } from "react-hot-toast";
import SharedPage from "./sharedPage";
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";
import ErrorPage from "./errorPage";
import AddBanner from "./add-banner";
import { AVAILABLE_YEARS } from "./salario-utils";

const salarioTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "RobotoSlab",
    },
  },
  //	palette: {
  //		mode: 'light',
  //		backgroundColor: '#16121e',
  //		primary: {
  //			main: '#3a66ff',
  //		},
  //		secondary: {
  //			main: '#3a66ff',
  //		},
  //	}
});

const darkTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "RobotoSlab",
    },
  },
  palette: {
    mode: "dark",
    backgroundColor: "#16121e",
    //		primary: {
    //			main: '#3a66ff',
    //		},
    //		secondary: {
    //			main: '#3a66ff',
    //		},
    //		text: {
    //			primary: '#ffffff',
    //			secondary: '#ffffff',
    //		},
  },
});

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function ThemePicker() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <HeaderFooterDiv>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-end",
          bgcolor: "background.default",
          color: "text.primary",
          py: 2,
          px: 2,
        }}
      >
        {theme.palette.mode === "dark" ? <DarkMode /> : <LightMode />}
        <Switch
          id="dark_mode"
          name="dark_mode"
          onChange={colorMode.toggleColorMode}
          checked={theme.palette.mode === "dark"}
        />
      </Box>
    </HeaderFooterDiv>
  );
}

export function recordEvent(eventName) {
  if (process.env.NODE_ENV === "production") {
    try {
      window.umami.trackEvent(eventName, {
        type: "submit",
      });
    } catch (err) {
      console.log("Could not load event lib. Check if you have content blocker.");
    }
  }
}

export default function App() {
  const [mode, setMode] = useState(useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [],
  );

  const theme = useMemo(() => {
    return mode === "light" ? salarioTheme : darkTheme;
  }, [mode]);

  // To use navigate in the NavBar component, we need to be in a router context.
  // https://stackoverflow.com/questions/70833727/using-react-router-v6-i-need-a-navbar-to-permanently-be-there-but-cant-display
  const Root = () => {
    return (
      <>
        <Toaster />
        <NavBar />
        <ThemePicker />
        <AddBanner />
        <Outlet />
        <Footer />
      </>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Navigate to="/salario-liquido/" />,
        },
        {
          path: "salario-liquido/",
          element: <SalaryForm year={AVAILABLE_YEARS[AVAILABLE_YEARS.length - 1]} type="liquido" />,
        },
        {
          path: "salario-liquido/:year",
          element: <SalaryForm type="liquido" />,
        },
        {
          path: "simulacao/:hash",
          element: <SharedPage />,
        },
        {
          path: "salario-bruto/",
          element: <SalaryForm year={AVAILABLE_YEARS[AVAILABLE_YEARS.length - 1]} type="bruto" />,
        },
        {
          path: "salario-bruto/:year",
          element: <SalaryForm type="bruto" />,
        },
        {
          path: "*",
          element: <ErrorPage />,
        },
      ],
    },
  ]);

  // CssBaseline and default themes info:
  // https://stackoverflow.com/questions/61520960/material-ui-does-not-change-typography-color-according-to-theme
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
