import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PoweredByRemoteFlags from "./powered-by-remoteflags";
import { HeaderFooterDiv } from "./AppStyles";

export default function Footer(props) {
  const { t, i18n } = useTranslation();

  return (
    <HeaderFooterDiv sx={{ marginBottom: "60px !important" }}>
      <Box
        sx={{
          mt: 2,
          mx: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ mt: 3, textAlign: "center" }} variant="body1">
          {t("footer-disclaimer")}
        </Typography>
        <Link href={t("footer-dre-link")} variant="body2">
          {t("footer-dre-label")}
        </Link>
      </Box>
      <PoweredByRemoteFlags sx={{ mt: 3, mb: 5 }} />
    </HeaderFooterDiv>
  );
}
