import * as React from "react";
import { styled, Box } from "@mui/system";
import { createHash } from "../salario-api";
import { Modal as BaseModal, Button, IconButton, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import { recordEvent } from "../App";

const URL_PREFIX = "https://esalario.pt/simulacao/";

export default function ShareButton(props) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [conteudo, setConteudo] = React.useState("");
  const { input, output } = props;

  function handleClose() {
    setOpen(false);
  }

  async function generateLink() {
    let hash = await createHash({ input: input, output: output });
    if (hash !== undefined) {
      setConteudo(URL_PREFIX + hash);
      setOpen(true);
      recordEvent("esalario-share-link-create");
    } else {
      toast.error(t("unsuccessfully-create-link"));
    }
  }

  async function shareIconClick() {
    await navigator.clipboard.writeText(conteudo);
    toast.success(t("link-copy-successfully"));
    recordEvent("esalario-share-link-copy");
    handleClose();
  }

  return (
    <div>
      <Button variant="contained" onClick={generateLink}>
        {t("button-share-name")}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <ModalContent sx={{ paddingTop: "8px" }}>
          <Box
            id="outraBox"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"bold"}>{t("simulation-link")}</Typography>
            <IconButton>
              <CloseIcon onClick={handleClose} fontSize="small"></CloseIcon>
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mr: -1,
            }}
          >
            <Link
              variant="body1"
              sx={{ width: "320px", wordWrap: "break-word" }}
              href={conteudo}
              target="_blank"
              rel="noreferrer noopener"
              onClick={handleClose}
            >
              {conteudo}
            </Link>
            <IconButton aria-label="copy" size="large" color="primary">
              <ContentCopyIcon onClick={shareIconClick}></ContentCopyIcon>
            </IconButton>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
}

const grey = {
  50: "#F3F6F9",
  200: "#DAE2ED",
  700: "#434D5B",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#FFF"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 4px 12px ${theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.20)"};
    padding: 1rem;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};
    font-family: "RobotoSlab";
    font-weight: 500;
    text-align: start;
    position: relative;


    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-right: 0.5rem;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;

    }
  `,
);
