import React from "react";
import { useParams } from "react-router-dom";

import ANO_2023_SalaryForm from "./2023-salary-form";
import ANO_2024_SalaryForm from "./2024-salary-form";
import { AVAILABLE_YEARS } from "../salario-utils";

export default function SalaryForm(props) {
  const { year: yearProps, type } = props;
  const { year: yearParam } = useParams();
  let final_year;

  if (yearParam) {
    final_year = yearParam;
  } else {
    final_year = yearProps;
  }

  const isValid = AVAILABLE_YEARS.includes(final_year);

  if (isValid) {
    if (final_year === "2023") {
      return <ANO_2023_SalaryForm type={type} />;
    }

    return <ANO_2024_SalaryForm type={type} />;
  } else {
    throw new Response("Not Found", { status: 404 });
  }
}
