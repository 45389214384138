import React, { useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { calculateSalary, calculateGrossSalaryFromNet, Engine } from "salario-engine-js";
import { PaddedDiv } from "../AppStyles";
import ResultsTable from "./2023-results-table";
import SalaryFormHeader from "./salary-form-header";
import FormSalary from "./form-salary";

const startState = {
  salario: undefined,
  situacao: "NAO_CASADO",
  dependentes: 0,
  incapacidade: false,
  valorSubAlimentacao: 9.6,
  cartaoSubAlimentacao: true,
  diasTrabalho: 22,
  valorSegSocial: 11,
  duodecimos: "tem",
};

const emptyState = {
  salario: "",
  situacao: "NAO_CASADO",
  dependentes: 0,
  incapacidade: false,
  valorSubAlimentacao: "",
  cartaoSubAlimentacao: false,
  diasTrabalho: 22,
  valorSegSocial: 11,
  duodecimos: "tem",
};

export default function ANO_2023_SalaryForm(props) {
  const { t, i18n } = useTranslation();
  const [salaryResponse, setSalaryResponse] = useState(null);
  const [salaryResponsePrimeiroSemestre, setSalaryResponsePrimeiroSemestre] = useState(null);
  const [fields, setFields] = useState(startState);
  const { type } = props;
  const [errorField, setErrorField] = useState({
    salario: false,
    dependentes: false,
    valorSubAlimentacao: false,
    diasTrabalho: false,
  });
  const [alert, setAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function _handleFieldChange(event) {
    setFields({ ...fields, [event.target.name]: event.target.value });
  }

  function _handleBooleanChange(event) {
    setFields({ ...fields, [event.target.name]: event.target.checked });
  }

  function _handleAlertClose() {
    setAlert(false);
  }

  function buildSalaryRequestFromState(engine) {
    // consider moving this to client
    const salaryRequest = {
      engine: engine,
      remuneracao: {
        salario: parseFloat(fields.salario || 0),
      },
      irs: {
        situacao: fields.situacao,
        dependentes: fields.dependentes >= 5 ? 5 : fields.dependentes || 0,
        incapacidade: fields.incapacidade,
      },
      subAlimentacao: {
        valor: parseFloat(fields.valorSubAlimentacao || 0),
        cartao: fields.cartaoSubAlimentacao,
        dias: parseInt(fields.diasTrabalho || 0),
      },
      segSocial: {
        valor: parseFloat(fields.valorSegSocial || 0),
      },
      duodecimos: {
        tipo: fields.duodecimos,
      },
    };
    return salaryRequest;
  }

  function _renderResultsTable() {
    return (
      <ResultsTable
        primeiroSemestre={salaryResponsePrimeiroSemestre}
        segundoSemestre={salaryResponse}
        inputs={fields}
        type={type}
      />
    );
  }

  function _recordEvent() {
    if (process.env.NODE_ENV === "production") {
      try {
        window.umami.trackEvent(`esalario-calcula-${type}-2023`, {
          type: "submit",
          salario: fields.salario,
        });
      } catch (err) {
        console.log("Could not load event lib. Check if you have content blocker.");
      }
    }
  }

  function _handleSubmit(e) {
    // with _handleInvalid, submit only triggers if form is valid, no need to validate here
    e.preventDefault();
    if (!_isFormValid()) {
      return;
    } else if (type === "liquido") {
      const salaryRequest = buildSalaryRequestFromState(Engine[Engine.SEGUNDO_SEMESTRE]);
      const salaryResponse = calculateSalary(salaryRequest);
      setSalaryResponse(salaryResponse);

      // also calc values for primeiro semestre, will go away in july 2023
      const salaryRequestPrimeiro = buildSalaryRequestFromState(Engine[Engine.PRIMERO_SEMESTRE]);
      const salaryResponsePimeiroSemestre = calculateSalary(salaryRequestPrimeiro);
      setSalaryResponsePrimeiroSemestre(salaryResponsePimeiroSemestre);
      _recordEvent();
    } else if (type === "bruto") {
      const salaryRequest = buildSalaryRequestFromState(Engine[Engine.SEGUNDO_SEMESTRE]);
      const grossSalaryValueSegundoSemestre = calculateGrossSalaryFromNet(salaryRequest);
      const salaryResponse = calculateSalary({
        ...salaryRequest,
        remuneracao: { salario: grossSalaryValueSegundoSemestre },
      });
      setSalaryResponse(salaryResponse);

      // also calc values for primeiro semestre, will go away in july 2023
      const salaryRequestPrimeiro = buildSalaryRequestFromState(Engine[Engine.PRIMERO_SEMESTRE]);
      const grossSalaryValuePrimeiroSemestre = calculateGrossSalaryFromNet(salaryRequestPrimeiro);
      const salaryResponsePimeiroSemestre = calculateSalary({
        ...salaryRequest,
        remuneracao: { salario: grossSalaryValuePrimeiroSemestre },
      });
      setSalaryResponsePrimeiroSemestre(salaryResponsePimeiroSemestre);
      _recordEvent();
    }
  }

  function _handleReset(event) {
    event.preventDefault();
    setSalaryResponse(null);
    setFields(emptyState);
  }

  function _validateAllFields() {
    const missingFields = new Set();

    for (const [key, value] of Object.entries(fields)) {
      if (value === undefined || value === "") {
        // now working as expected, the state changes after func return
        setErrorField({ ...errorField, [key]: true });
        console.log(key);
        if (key === "salario") {
          missingFields.add(t(`salary-form-error-${key}-${type}`));
        } else {
          missingFields.add(t(`salary-form-error-${key}`));
        }
      }
    }
    return missingFields;
  }

  function _isFormValid() {
    // on reset, fields are empty, but no form event triggers validation, manual validation needed
    const missingFields = _validateAllFields();
    if (missingFields.size > 0) {
      setAlert(true);
      setErrorMessage(t("salary-form-required") + Array.from(missingFields).join("; "));
      return false;
    }
    return true;
  }

  function renderForm() {
    let path = "";
    let salaryName = "";
    if (type === "bruto") {
      path = "salario-bruto";
      salaryName = "liquido";
    } else if (type === "liquido") {
      path = "salario-liquido";
      salaryName = "bruto";
    }
    return (
      <>
        <SalaryFormHeader type={type} path={path} />
        <FormSalary
          _handleReset={_handleReset}
          _handleSubmit={_handleSubmit}
          fields={fields}
          _handleFieldChange={_handleFieldChange}
          _handleBooleanChange={_handleBooleanChange}
          type={salaryName}
        />
      </>
    );
  }

  return (
    <PaddedDiv>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert}
        autoHideDuration={3500}
        onClose={_handleAlertClose}
      >
        <Alert onClose={_handleAlertClose} severity="info" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Box>
        {renderForm()}
        {salaryResponse && salaryResponsePrimeiroSemestre && _renderResultsTable()}
      </Box>
    </PaddedDiv>
  );
}
