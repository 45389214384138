import React, { useEffect, useState } from "react";
import { Box, Card, CardHeader, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ShareButton from "./shareButton";
import { render } from "@testing-library/react";

export default function ResultsTable(props) {
  const { t, i18n } = useTranslation();
  const { salary, inputs, type } = props;
  const [inputData, setInputs] = useState({ type: type, ...inputs });
  const [data, setData] = useState(salary);

  useEffect(() => {
    setData(salary);
    setInputs({type: type, ...inputs });
  }, [salary]);

  function _calcSalaryResult(value) {
    return parseFloat(value.toFixed(2));
  }

  function _formatPercentage(value) {
    return parseFloat((value * 100).toFixed(2));
  }

  function _renderNetSalaryCardHeader() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 1,
        }}
      >
        <Typography variant="h6" sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
          {t("results-table-salary")}
          <Typography sx={{ ml: 1 }} variant="subtitle2">
            {t("results-table-salary-subheader")}
          </Typography>
        </Typography>
        <Typography variant="h6">{_calcSalaryResult(data.netSalary + data.foodAllowance)} €</Typography>
      </Box>
    );
  }

  function _renderGrossSalaryCardHeader() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 1,
        }}
      >
        <Typography variant="h6" sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
          {t("results-table-gross-salary-company")}
        </Typography>
        <Typography variant="h6">{_calcSalaryResult(data.grossSalaryCompany)} €</Typography>
      </Box>
    );
  }

  function _renderCardHeader() {
    if (type === "liquido") {
      return _renderNetSalaryCardHeader();
    } else if (type === "bruto") {
      return _renderGrossSalaryCardHeader();
    }
  }

  return (
    <Card
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
        mt: 5,
        borderRadius: "16px",
      }}
    >
      <CardHeader
        sx={{ px: 3, background: (theme) => theme.palette.primary.main }}
        title={_renderCardHeader()}
        titleTypographyProps={{
          color: (theme) => theme.palette.primary.contrastText,
        }}
      ></CardHeader>
      <Box sx={{ py: 2, px: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography variant="body1">{t("results-table-net")}</Typography>
          <Typography variant="body1">{data.netSalary} €</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography variant="body1">{t("results-table-sub-alimentacao")}</Typography>
          <Typography variant="body1">{data.foodAllowance} €</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography variant="body1">{t("results-table-tax-rate")}</Typography>
          <Typography variant="body1">{_formatPercentage(data.taxRate)} %</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography variant="body1">{t("results-table-tax-value")}</Typography>
          <Typography variant="body1">{data.taxValue} €</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography variant="body1">{t("results-table-social-security")}</Typography>
          <Typography variant="body1">{data.socialSecurity} €</Typography>
        </Box>
        <Divider sx={{ mt: 3, mb: 2 }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography variant="body1">{t("results-table-company-cost")}</Typography>
          <Typography variant="body1">{data.totalCostCompany} €</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography variant="body1" marginLeft="16px">
            {t("results-table-gross-salary-company")}
          </Typography>
          <Typography variant="body1">{data.grossSalaryCompany} €</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography variant="body1" marginLeft="16px">
            {t("results-table-company-sub-alimentacao")}
          </Typography>
          <Typography variant="body1">{data.foodAllowanceCompany} €</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography variant="body1" marginLeft="16px">
            {t("results-table-tsu-company")}
          </Typography>
          <Typography variant="body1">{data.tsuCompany} €</Typography>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <ShareButton input={inputData} output={data}></ShareButton>
        </Box>
      </Box>
    </Card>
  );
}
